<template>
	<div class="animate-in step-2" :class="{loaded: isLoaded, 'is-submitted':isSubmitted}">
		<b-form @submit.prevent="submit">
			<h2 class="ul">Case Details</h2>
			<dynamic-form-controls :questions="questions" @change="validate" />
			<div v-if="isLoaded" class="form-group">
				<label for>Want to add more information to the report? You can upload documents here.</label>
				<additional-doc v-for="(file, index) in files" :key="index" :file="file" @remove="deleteFile(index)" /> 
				<b-form-file id="upload" v-model="newFiles" :accept="acceptTypes" class="no-button" multiple placeholder="Drag files here or click to browse" @input="checkFiles(newFiles)" />
				<div class="fs-13 mt-1 text-mid-gray"><p>Allowed file types: PDF, DOCX, XLSX, PPTX, JPG, PNG, MP3 and MP4</p></div>
			</div>
			<div v-if="questions.length" class="d-flex form-controls">
				<btn-back />
				<btn-save />	
				<b-button type="submit">Next</b-button>
			</div>
		</b-form>
	</div>
</template>

<script>

import {isQuestionView, canUpload, canCreatePassword, fileUploadType} from '@/mixins'
import { AdditionalDoc } from "@/components/ui";
export default {
	name: 'Step2',
	components: {
		AdditionalDoc
	},
	mixins: [isQuestionView, canUpload, canCreatePassword, fileUploadType],
	data() {
		return {
			questionGroup: 'details', 
			prevStep: 'step1'
		}
	},
	computed: {
		nextStep() {
			return this.$store.state.org.hasAdditionalQuestions ? 'step3' : 'review'
		}
	},
	methods: {
		save() {
			this.$store.commit('login/set', {state: 'save'})
			this.createPassword()
		}
	},
	metaInfo: {
		title: 'Submit Report - Step 2',
	}
}
</script>
<style lang="scss">
.step-2 label.optional:after{
	content: none;
}
</style>

